export default function searchReducer(state, action) {
  switch (action.type) {
    case 'clear_screen': {
      return {
        ...state,
        postcode: '',
        accountNumber: '',
        gapAddresses: [],
        selectedGaps: [],
        abpAddressId: null,
        matchedAddress: null,
        addressData: null,
        loading: false,
        propertyType: null,
        rejectionReason: null,
        gapStatus: null,
      }
    }
    case 'set_postcode': {
      return {
        ...state,
        postcode: action.payload,
      }
    }
    case 'set_account_number': {
      return {
        ...state,
        accountNumber: action.payload,
      }
    }
    case 'search_results': {
      return {
        ...state,
        postcode: action.payload.postcode ?? '',
        accountNumber: action.payload.accountNumber ?? '',
        abpAddressId: null,
        matchedAddress: null,
        gapAddresses: action.payload.customers,
        addressData: action.payload.addresses,
        showMap: true,
        loading: false,
      };
    }
    case 'update_selected_gaps': {
      if (action.payload.add) {
        return {
          ...state,
          selectedGaps: [...state.selectedGaps, action.payload.uprn]
        }
      }

      return {
        ...state,
        selectedGaps: state.selectedGaps.filter(g => g !== action.payload.uprn)
      }
    }
    case 'toggle_select_all_gaps': {
      const allSelected = state.gapAddresses.reduce((acc, curr) => {
        if (!acc) return acc;
        return state.selectedGaps.includes(curr.ABP_UPRN)
      }, true)

      if (allSelected) {
        return {
          ...state,
          selectedGaps: [],
        }
      }

      return {
        ...state,
        selectedGaps: state.gapAddresses.map(address => address.ABP_UPRN),
      }
    }
    case 'set_gap_status': {
      return {
        ...state,
        gapStatus: action.payload,
      };
    }
    case 'submit_customer_bulk': {
      return {
        ...state,
        gapStatus: null,
        propertyType: null,
        rejectionReason: null,
        selectedGaps: [],
      }
    }
    case 'purchase_customer': {
      return {
        ...state,
        occupierDetails: action.payload,
      };
    }
    case 'set_notes': {
      return {
        ...state,
        notes: action.payload.slice(0, 500) ,
      };
    }
    case 'submit_notes': {
      return {
        ...state,
      };
    }
    case 'select_match_address': {
      if (state.matchedAddress === action.payload.name) {
        return {
          ...state,
          matchedAddress: null, 
          abpAddressId: null,
        }
      }
      return {
        ...state,
        matchedAddress: action.payload.name,
        abpAddressId: action.payload.id,
      };
    }
    case 'show_map': {
      return {
        ...state,
        showMap: action.payload,
      };
    }
    case 'set_rejection_reason': {
      return {
        ...state,
        rejectionReason: action.payload != "Select Reason" ? action.payload : null,
      }
    }
    case 'set_property_type': {
      return {
        ...state,
        propertyType: action.payload != "Select Type" ? action.payload : null       
      }
    }
    default: {
      console.log("Unknown action: " + action.type)
    }
  }
}

import React, { Component, useCallback, useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import endpoints from '../../../helpers/endpoints';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import './GapSitesAlerts.scss'
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
// import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import Text from 'recharts/lib/component/Text';
import S3Modal from '../../../components/s3Modal/S3Modal';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import session from '../../../stores/session';
import hasPermission from '../../../helpers/permissions';
import styles from '../../../components/product-data-filter/product-data-filter.module.scss'
import axios from 'axios';

const localised = Intl.NumberFormat('en-GB');

export default view(
    withRouter(
        class GapSitesAlertsSummary extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    dataReady: false,
                    data: null,
                    group: '',
                    filters: {
                        CT_Match: null,
                        Match_Status: [],
                        Category: null,
                        Confidence_Rating: [],
                        Postcode_Region: [],
                    },
                    tableOrGraph: 'table',
                    files: [],
                    modalIsOpen: false,
                    modalMode: null,
                    modalMessage: null,
                    groupings: [],
                    bulkBuyModalIsOpen: false,
                    bulkBuyUserModalIsOpen: false,
                    bulkAllocateModalIsOpen: false,
                    translations: null,
                }
            }

            setGroup = (field) => {
                if (this.state.group == field) {
                    this.setState({
                        group: ''
                    }, () => {
                        this.query();
                    });
                } else {
                    this.setState({
                        group: field
                    }, () => {
                        this.query();
                    });
                }
            }

            getGroupings = () => {
                request(true)
                    .get(endpoints.GAP_SITES_ALERTS_GROUPINGS)
                    .then(e => {
                        this.setState({
                            groupings: e.data,
                            group: e.data.find(_ => _.default)
                        })
                    })
            }
            
            filter = (field, value) => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        [field]: value
                    }
                }, () => {
                    this.query();
                });
            }

            query = () => {
                this.setState({
                    dataReady: false,
                }, () => {
                    request(true).get(endpoints.GAP_SITES_ALERTS_SUMMARY, {
                        params: {
                            date: this.state.date,
                            filter: this.state.filters,
                            group: this.state.group?.target_column
                        },
                        doesCancel: true,
                    }).then(r => {
                        this.setState({
                            data: r.data,
                            dataReady: true,
                        });
                    })
                })
            }

            clearFilters = () => {
                this.setState({
                    filters: {
                        CT_Match: null,
                        Match_Status: [],
                        Category: null,
                        Confidence_Rating: [],
                    },
                }, () => {
                    this.query();
                });
            }

            setTableOrGraph = (ev) => {
                this.setState({
                    tableOrGraph: ev.target.value
                });
            }

            componentDidMount() {
                const groupings = this.getGroupings();
                request(true).get('/translate?key=gap_sites_alerts').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                Promise.all([groupings]).finally(() => {
                    this.query();
                })
                useWSProduct('gap_sites_alerts');// eslint-disable-line
            }

            componentWillUnmount() {
                useWSProduct('gap_sites_alerts', 'unlisten');// eslint-disable-line
            }

            closeBulkAllocateModal = () => {
                this.setState({bulkAllocateModalIsOpen: false})
            }
            
            openBulkAllocateModal = () => {
                this.setState({bulkAllocateModalIsOpen: true})
            }

            openBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: true
                });
            }

            closeBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: false
                });
            }

            openBulkBuyUserModal = () => {
                this.setState({
                    bulkBuyUserModalIsOpen: true
                });
            }

            closeBulkBuyUserModal = () => {
                this.setState({
                    bulkBuyUserModalIsOpen: false
                });
            }

            CustomizedAxisTickMultiLine = ({x, y, payload}) => {
                return (
                   <Text x={x} y={y} width={60} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
                )
            };

            openDownloadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'download',
                });
            }

            openUploadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'upload',
                });
            }

            openUnallocateModal = () => {
                this.setState({
                    modalMode: 'unallocate',
                });
            }

            closeModal = () => {
                this.setState({
                    modalIsOpen: false,
                    modalMode: '',
                });
            }

            render () {
                const totals = Array(8).fill(0);

                return (
                    <GenericPageContainer
                    containerWidth="92vw"
                        title={`${this.state.translations ? this.state.translations['module_title'] : ''} - Summary`}
                        titleRight={<div>
                        { hasPermission('GSA', 'DOWNLOAD') ?
                            <button className="navigate" onClick={this.openDownloadModal}>Download Return File</button>
                        : null }
                        { hasPermission('GSA', 'UPLOAD') ?
                            <button className="navigate" onClick={this.openUploadModal}>Issue Input File</button>
                        : null }
                        { hasPermission('GSA', 'DASHBOARD') ?
                            <Link className="navigate" to="/portal/gap-sites-alerts/dashboard">
                                To Dashboard
                            </Link>
                        : null }
                        { (hasPermission('GSA', 'WORKFLOW')) ?
                          <Link className="navigate" to="/portal/gap-sites-alerts/search">
                            To Search
                          </Link>
                          : null }
                        { (hasPermission('GSA', 'WORKFLOW')) ?
                            <Link className="navigate" to="/portal/gap-sites-alerts/workflow">
                                To Workflow
                            </Link>
                        : null }
                        </div>}
                    >
                        <div className="grid grid-gap-15" id="void-cot-alerts-page">
                                <div className='gsa-summary'>
                                <GenericDataChart
                                    groupingsEndpoint={endpoints.GAP_SITES_ALERTS_GROUPINGS}
                                    excludeColumnsFromTotal={["Gap Accepted", "Gap Rejected"]}
                                    data={this.state.data}
                                    dataReady={this.state.dataReady}
                                    selectedGroupBy={this.state.group}
                                    setSelectectGroupBy={this.setGroup}
                                    displayModeInitial="table"
                                    // overrideTableHeader="Occupier Banding"
                                    sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
                                    xAxisProps={{interval: 0}}
                                    customBarTick={this.CustomizedAxisTickMultiLine}
                                ></GenericDataChart>
                                <GenericProductFilterContainer clearFilters={this.clearFilters} style={{height: 'min-content', position: 'sticky', top: '10px'}}>
                                    <ProductDataFilter
                                        filterState={this.state.filters}
                                        applyFilter={this.filter}
                                        dataFilterEndpoint={endpoints.GAP_SITES_ALERTS_FILTERS}
                                        inputFilters={['Gap_Sites_Alerts_Region', 'Gap_Sites_Alerts_Outward']}
                                    />
                                    <div className='grid grid-columns-2 grid-gap-5 mta'>
                                    { (hasPermission('GSA', 'ALLOCATE')) ?
                                        <button className="button bulk-buy-button" style={{marginTop: '15px'}} onClick={this.openBulkAllocateModal}>
                                            Allocate
                                        </button>
                                    : '' }
                                    { (hasPermission('GSA', 'UNALLOCATE')) ?
                                      <button className="button bulk-buy-button" style={{marginTop: '15px'}} onClick={this.openUnallocateModal}>
                                        Unallocate
                                      </button>
                                      : '' }
                                    { (hasPermission('GSA', 'DOWNLOAD')) ?
                                        <button className="button bulk-buy-button" style={{marginTop: '15px'}} onClick={this.openBulkBuyModal}>
                                            Create Download File
                                        </button>
                                    : ''}
                                    { (hasPermission('GSA', 'DOWNLOAD') && session.company.gsa_bulk_buy_user) ?
                                        <button className="button bulk-buy-button" style={{marginTop: '15px'}} onClick={this.openBulkBuyUserModal}>
                                            {this.state.translations ? this.state.translations['bulk_buy_user'] : ''}
                                        </button>
                                    : ''}
                                    </div>
                                </GenericProductFilterContainer>
                                </div>
                        <BulkAllocate showZeroAllocationMessage={false} workflowUsersEndpoint={endpoints.GAP_SITES_ALERTS_WORKFLOW_USERS} bulkEndpoint={`${endpoints.GAP_SITES_ALERTS_BULK}`} urlParams={{allocateCount: true}} modalIsOpen={this.state.bulkAllocateModalIsOpen} closeModal={this.closeBulkAllocateModal} filters={this.state.filters} />
                        <BulkAllocate 
                          workflowUsersEndpoint={endpoints.GAP_SITES_ALERTS_WORKFLOW_USERS} 
                          bulkEndpoint={endpoints.GAP_SITES_ALERTS_BULK} 
                          unallocateEndpoint={endpoints.GAP_SITES_ALERTS_UNALLOCATE_GET} 
                          modalIsOpen={this.state.modalMode === 'unallocate'} 
                          mode={this.state.modalMode} 
                          closeModal={this.closeModal} 
                          filters={this.state.filters} 
                          date={this.state.date} 
                          reallocate={hasPermission('GSA', 'ALLOCATE') && hasPermission('GSA', 'UNALLOCATE')}
                        />,
                        </div>
                        <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.GAP_SITES_ALERTS_UPLOAD} uploadUrl={endpoints.GAP_SITES_ALERTS_DOWNLOAD}/>
                        <BulkBuy
                            SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
                            SentenceSecond={() => <></>}
                            bulkLimitErrorSentence={"You cannot download 0 accounts, please change your selection"}
                            buyButtonText="Create File" completeOnFirstStep={true} workflowUsersEndpoint={endpoints.GAP_SITES_ALERTS_WORKFLOW_USERS} bulkEndpoint={`${endpoints.GAP_SITES_ALERTS_BULK}`} modalIsOpen={this.state.bulkBuyModalIsOpen} closeModal={this.closeBulkBuyModal} filters={this.state.filters} date={this.state.date}
                            purchaseLabel="Potential Gaps" limitInputDisabled={true}
                        />
                        <BulkBuy
                            SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
                            SentenceSecond={() => <></>}
                            bulkLimitErrorSentence={"You cannot download 0 accounts, please change your selection"}
                            buyButtonText="Create File" 
                            completeOnFirstStep={true} 
                            workflowUsersEndpoint={endpoints.GAP_SITES_ALERTS_WORKFLOW_USERS} 
                            bulkEndpoint={`${endpoints.GAP_SITES_ALERTS_BULK_USER}`} 
                            modalIsOpen={this.state.bulkBuyUserModalIsOpen} 
                            closeModal={this.closeBulkBuyUserModal} 
                            filters={this.state.filters} 
                            date={this.state.date}
                            purchaseLabel="Accounts" 
                            limitInputDisabled={true}
                        />
                    </GenericPageContainer>
                )
            }
        }
    )
)

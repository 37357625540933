import React, { useState, useCallback, useReducer } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer.jsx'
import request from '../../../helpers/request.js'
import endpoints from '../../../helpers/endpoints.js'
import { view } from '@risingstack/react-easy-state'
import './GapSitesAlerts.scss';
import searchReducer from './GapSitesAlertsSearchReducer.js'

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session.js'
import hasPermission from '../../../helpers/permissions.js';
import { useTranslations } from '../../../helpers/hooks.js'
import ReactModal from 'react-modal'

export default view(
  withRouter(
    function GapSitesAlertsSearch() {
      const translations = useTranslations('gap_sites_alerts');

      const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false)

      const initialState = {
        postcode: '',
        accountNumber: '',
        gapAddresses: [],
        selectedGaps: [],
        abpAddressId: null,
        matchedAddress: null,
        addressData: null,
        loading: false,
        propertyType: null,
        rejectionReason: null,
        gapStatus: null,
      }

      const [state, dispatch] = useReducer(searchReducer, initialState)

      const doPostcodeSearch = useCallback(() => {
        if (!state.postcode) return dispatch({ type: 'clear_screen' })

        request(true).post(endpoints.GAP_SITES_ALERTS_GET_CUSTOMERS_BY_POSTCODE, {
          postcode: state.postcode,
        }).then(e => {
          if (!e.data) {
            window.alert(`No data found for postcode ${state.postcode}`)
            dispatch({ type: 'clear_screen' })
            return 
          }

          dispatch({ type: 'search_results', payload: e.data })
        }).catch(e => {
            console.log(e)
            window.alert("Search was unsuccessful")
          })

      }, [state.postcode])

      const doAccountSearch = useCallback(() => {
        if (!state.accountNumber) return

        request(true).post(endpoints.GAP_SITES_ALERTS_GET_CUSTOMERS_BY_POSTCODE, {
          accountNumber: state.accountNumber,
        }).then(e => {
          if (!e.data) {
            window.alert(`No data found for account number ${state.accountNumber}`)
            dispatch({ type: 'clear_screen' })
            return
          }

          dispatch({ type: 'search_results', payload: e.data })
        }).catch(e => {
            console.log(e)
            window.alert("Search was unsuccessful")
          })
      }, [state.accountNumber])

      const handleSubmitBulk = useCallback(() => {
        if (!state.selectedGaps) return

        request(true).post(endpoints.GAP_SITES_ALERTS_UPDATE_CUSTOMER_BULK, {
          selectedGaps: state.selectedGaps,
          State: state.gapStatus,
          RejectionReason: state.rejectionReason,
          PropertyType: state.propertyType,
          ABP_ADDRESS_ID: state.abpAddressId,
        }).then(e => {
            dispatch({ type: 'submit_customer_bulk' })
            window.alert('Details successfully updated')
            doPostcodeSearch()
        }).catch(e => {
            console.log(e);
            window.alert('Failed to submit')
          })
      }, [state.selectedGaps, state.gapStatus, state.rejectionReason, state.propertyType, state.abpAddressId])

      const handleAssignPostcode = useCallback(() => {
        if (!state.postcode || !hasPermission('GSA', 'WORKFLOW')) return

        request(true).post(endpoints.GAP_SITES_ALERTS_POSTCODE_ALLOCATE, {
          postcode: state.postcode,
        }).then(e => {
            window.alert(`Successfully assigned postcode ${state.postcode}`)
          }).catch(e => {
            console.log(e)
            window.alert(`Failed to assign postcode ${state.postcode}`)
          })
      }, [state.postcode])

      return (
        <GenericPageContainer
          title={`${translations ? translations['module_title'] : ''} - Search`}
          titleRight={
            <div>
              <Link className="navigate" to="/portal/gap-sites-alerts">
                To Summary
              </Link>
              { hasPermission('GSA', 'WORKFLOW') ?
                <Link className="navigate" to="/portal/gap-sites-alerts/workflow">
                  To Workflow
                </Link>
                : null }
              { hasPermission('GSA', 'DASHBOARD') ?
                <Link className="navigate" to="/portal/gap-sites-alerts/dashboard">
                  To Dashboard
                </Link>
                : null }
            </div>
          }
          containerWidth={"90vw"}
        >
          <div className="gsa" style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            { state.loading ?
              <i style={{ marginTop: '10px', marginLeft: '10px', position: 'absolute', top: '15vh', left: '50vw' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
              : ''}
            <div>
              <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
                <col width={180} />
                <thead>
                  <tr>
                    <th colSpan={12}>{translations['Header_Summary']}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width={400}>{translations['Postcode']}</td>
                    <td colSpan={4}>
                      <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                        <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_postcode', payload: e.target.value })} value={state.postcode} />
                        <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={!state.loading ? doPostcodeSearch : null}></span>
                      </div>
                    </td>
                    <td width={400}>{translations['Account_Number']}</td>
                    <td colSpan={4}>
                      <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                        <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_account_number', payload: e.target.value })} value={state.accountNumber} />
                        <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={!state.loading ? doAccountSearch : null}></span>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <button className='button background-primary colour-white' style={{padding: '0'}} onClick={handleAssignPostcode}>
                        Bulk Allocate Postcode
                      </button>
                    </td>
                  </tr>
                  <tr>
                    {/* <td colSpan={2}></td> */}
                    <td>{translations['Status']}</td>
                    <td colSpan={4}>
                      <select value={state.gapStatus ?? "New"} onChange={_ => dispatch({ type: 'set_gap_status', payload: _.target.value })} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                        <option value={"New"}>New</option>
                        <option value={"In Progress"}>In Progress</option>
                        <option value={"GAP Accepted"}>GAP Accepted</option>
                        <option value={"GAP Rejected"}>GAP Rejected</option>
                      </select>
                    </td>
                    {
                      state.gapStatus === "GAP Rejected" ?
                        <>
                          <td colSpan={2}>Rejection Reason</td>
                          <td colSpan={3}>
                            <select disabled={state.loading} value={state.rejectionReason ?? null} onChange={_ => dispatch({ type: 'set_rejection_reason', payload: _.target.value })} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                              <option value={""}>Select Reason</option>
                              <option value={"Bulk account"}>Bulk account</option>
                              <option value={"New Accounts Process"}>New Accounts Process</option>
                              <option value={"Property is billing - Address Quality"}>Property is billing - Address Quality</option>
                              <option value={"Property is billing - No Action Required"}>Property is billing - No Action Required</option>
                              <option value={"Query with IDenteq"}>Query with IDenteq</option>
                              <option value={"MOD/Private Supply"}>MOD/Private Supply</option>
                              <option value={"Other"}>Other</option>
                            </select>
                          </td>
                        </>
                        :
                        state.gapStatus === "GAP Accepted" && session.company.gsa_use_prop_type ?
                          <>
                            <td colSpan={2}>Property Type</td>
                            <td colSpan={3}>
                              <select disabled={state.loading} value={state.propertyType ?? null} onChange={_ => dispatch({ type: 'set_property_type', payload: _.target.value })} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                                <option value={""}>Select Type</option>
                                <option value={"DT"}>Detached</option>
                                <option value={"SD"}>Semi-Detached</option>
                                <option value={"TE"}>Terraced</option>
                                <option value={"OT"}>Other</option>
                              </select>
                            </td>
                          </>
                          :
                          <>
                            <td colSpan={5}></td>
                          </>
                    }
                    <td style={{textAlign: 'right'}} colSpan={2}>
                      { 
                        state.selectedGaps.length > 0 && 
                          state.gapStatus !== "New" && 
                          state.gapStatus !== null && 
                          !state.loading && 
                          (state.gapStatus === "GAP Rejected" ? state.rejectionReason : true) && 
                          (state.gapStatus === "GAP Accepted" && session.company.gsa_use_prop_type ? state.propertyType : true) && 
                          hasPermission('GSA', 'PURCHASE') ?
                          <button className='button background-primary colour-white' style={{padding: '0'}} onClick={handleSubmitBulk}>
                            Submit
                          </button>
                          :
                          <button disabled={true} className='button disabled' style={{padding: '0'}}>
                            Submit
                          </button>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{fontWeight: 'bold'}} colSpan={5}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>ABP Gap Addresses</span>
                        <label htmlFor="select-all-gaps">
                          <span style={{paddingRight: "0.5em"}}>Select all</span> 
                          <input 
                            id="select-all-gaps" 
                            type="checkbox" 
                            checked={ state.gapAddresses.length && state.gapAddresses.reduce((acc, curr) => {
                              if (!acc) return acc;
                              return state.selectedGaps.includes(curr.ABP_UPRN)
                            }, true) }
                            onChange={() => {
                              dispatch({ type: 'toggle_select_all_gaps' })
                            }}
                            style={{display: "inline", width: "unset"}}
                          />
                        </label>
                      </div>
                    </td>
                    <td style={{fontWeight: 'bold'}} colSpan={4}>Matched CT Addresses</td>
                    <td style={{fontWeight: 'bold'}} colSpan={1}>Account Number</td>
                    <td style={{fontWeight: 'bold'}} colSpan={1}>Gap Status</td>
                    <td style={{fontWeight: 'bold'}} colSpan={1}>Occupier Score</td>
                  </tr>
                  { state.gapAddresses.map(address => (
                    <tr key={address.ABP_UPRN}>
                      <td colSpan={5}>
                        <label htmlFor={`checkbox--${address.ABP_UPRN}`} style={{display: "flex", justifyContent: "space-between"}}>
                          {address.ABP_Address}
                          <input 
                            id={`checkbox--${address.ABP_UPRN}`} 
                            type="checkbox" 
                            checked={ state.selectedGaps.includes(address.ABP_UPRN) }
                            onChange={() => {
                              if (state.selectedGaps.includes(address.ABP_UPRN)) {
                                dispatch({ type: 'update_selected_gaps', payload: { uprn: address.ABP_UPRN, add: false } })
                              } else {
                                dispatch({ type: 'update_selected_gaps', payload: { uprn: address.ABP_UPRN, add: true } })
                              }
                            }}
                            style={{display: "inline", width: "unset"}}
                          />
                        </label>
                      </td>
                      <td colSpan={4}>{address.CT_Address}</td>
                      <td colSpan={1}>{address.Account_Number}</td>
                      <td colSpan={1}>{address.state || "New"}</td>
                      <td colSpan={1}>{address.VCA_Occupier_Score}</td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: '17fr 16fr 14fr', gridAutoFlow: 'column', gap: 10}}>
              <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                <table className='table borders smaller-text squish'>
                  <thead style={{position: 'sticky', top: '0px'}}>
                    <tr>
                      <th>Client Addresses</th>
                      <th style={{width: '20%'}}>Status</th>
                      <th style={{width: '10%'}}>Match</th>
                    </tr>
                  </thead>
                  <tbody style={{whiteSpace: 'nowrap'}}>
                    {
                      state.addressData  ? 
                        state.addressData.client.map((address, index) => {
                          return (
                            <tr style={{height: '35px'}}>
                              <td style={{textAlign: 'left', fontSize: '12px'}}>{address.ClientAddress}</td>
                              <td style={{fontSize: '12px'}}>{typeof address.match !== 'undefined' ? address.match === 1 ? "Matched" : "Unmatched" : ""}</td>
                              <td><input type="checkbox" name={address.ClientAddress} id={address.ADDRESS_ID} onChange={ address.ClientAddress ? (e) => dispatch({ type: 'select_match_address', payload: e.target }) : null } checked={ 
                                state.matchedAddress ?
                                  address.ClientAddress === state.matchedAddress
                                  :
                                  false
                              }></input></td>
                            </tr>
                          )
                        })
                        :
                        <tr><td></td><td></td></tr>
                    }
                  </tbody>
                </table>
              </div>
              <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                <table className='table borders smaller-text squish'>
                  <thead style={{position: 'sticky', top: '0px'}}>
                    <tr>
                      <th>ABP Addresses</th>
                    </tr>
                  </thead>
                  <tbody style={{whiteSpace: 'nowrap'}}>
                    {
                      state.addressData ? 
                        state.addressData.abp.map((address, index) => {
                          return (
                            <tr style={{height: '35px'}}>
                              <td style={{textAlign: 'left', fontSize: '12px'}}>{address.ABP_Address}</td>
                            </tr>
                          )
                        })
                        :
                        <tr><td></td></tr>
                    }
                  </tbody>
                </table>
              </div>
              <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                <table className='table borders smaller-text squish'>
                  <thead style={{position: 'sticky', top: '0px'}}>
                    <tr>
                      <th>Council Tax Addresses</th>
                    </tr>
                  </thead>
                  <tbody style={{whiteSpace: 'nowrap'}}>
                    {
                      state.addressData  ? 
                        state.addressData.ct.map((address, index) => {
                          return (
                            <tr style={{height: '35px'}}>
                              <td style={{textAlign: 'left', fontSize: '12px'}}>{address.FullAddress}</td>
                            </tr>
                          )
                        })
                        :
                        <tr><td></td></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </GenericPageContainer>
      )
    }
  )
);
